<template>
  <div id="page">
    <PageHeader
      :pageTitle="title + ' - ' + pageTitle"
      :pageDescription="pageDescription"
    ></PageHeader>

    <v-row>
      <v-col cols="8">
        <SMTP :companyId="this.$route.params.id" />
      </v-col>

      <v-col cols="4">
        <Subnavigation
          root="/csm/company-manager/:id"
          :pageTitle="pageTitle"
        ></Subnavigation>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import PageHeader from "@/components/ui/PageHeader.vue";
import Subnavigation from "@/components/ui/Subnavigation.vue";
import SMTP from "@/components/companies/mailer/SMTP.vue";

/* GET companies/{companyId}/smtp
GET PATCH DELETE companies/{companyId}/smtp/{smtpId}
GET companies/{companyId}/smtp/{smtpId}/checkConnection
POST companies/{companyId}/smtp */

export default {
  name: "Mailer",

  components: {
    PageHeader,
    Subnavigation,
    SMTP,
  },

  data() {
    return {
      pageTitle: "Mailer",
      pageDescription: "Mail-Optionen der Firma",
      title: "",
    };
  },

  created: function () {
    this.getData(); // call it immediatly
  },

  methods: {
    async getData() {
      this.preloader = true;
      /* Fetching Companyname for Headline */
      /* USEING REUSEABLE MIXIN METHOD */
      const response = await this.getRequest(
        "companies/" + this.$route.params.id
      ); // await data from mixin
      this.title = response.data.data[0].name;
      this.preloader = false;
    },
  },

  mixins: [apiRequest],
};
</script>
