<template>
  <v-card>
    <v-card-title>
      <v-row>
        <v-col cols="12">
          <h3>SMTP</h3>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text v-if="!preloader">
      <template v-if="data">
        <Patcher
          :itemValue="data.isActive"
          itemName="isActive"
          :route="`${patchRoute}`"
          inputElement="switch"
          inputType=""
          :previewValue="data.isActive"
          itemLabel="Aktiv"
          :hideLabel="true"
          @emit="data.isActive = $event.value"
        ></Patcher>

        <Patcher
          :itemValue="data.defaultFrom"
          itemName="defaultFrom"
          itemLabel="E-Mail"
          :hideLabel="false"
          :route="`${patchRoute}`"
          inputElement="input"
          inputType="email"
          selectOptions=""
          itemValueKey=""
          itemTextKey=""
          :previewValue="data.defaultFrom"
          @emit="data.defaultFrom = $event.value"
        ></Patcher>

        <Patcher
          :itemValue="data.servername"
          itemName="servername"
          itemLabel="Servername"
          :hideLabel="false"
          :route="`${patchRoute}`"
          inputElement="input"
          inputType="text"
          selectOptions=""
          itemValueKey=""
          itemTextKey=""
          :previewValue="data.servername"
          @emit="data.servername = $event.value"
        ></Patcher>

        <Patcher
          :itemValue="data.port"
          itemName="port"
          itemLabel="Port"
          :hideLabel="false"
          :route="`${patchRoute}`"
          inputElement="input"
          inputType="number"
          selectOptions=""
          itemValueKey=""
          itemTextKey=""
          :previewValue="data.port"
          @emit="data.port = $event.value"
        ></Patcher>

        <Patcher
          :itemValue="data.username"
          itemName="username"
          itemLabel="Benutzername"
          :hideLabel="false"
          :route="`${patchRoute}`"
          inputElement="input"
          inputType="text"
          selectOptions=""
          itemValueKey=""
          itemTextKey=""
          :previewValue="data.username"
          @emit="data.username = $event.value"
        ></Patcher>

        <Patcher
          :itemValue="data.password"
          itemName="password"
          itemLabel="Passwort"
          :hideLabel="false"
          :route="`${patchRoute}`"
          inputElement="input"
          inputType="text"
          selectOptions=""
          itemValueKey=""
          itemTextKey=""
          :previewValue="data.password"
          @emit="data.password = $event.value"
        ></Patcher>

        <Patcher
          :itemValue="data.smtpSecure"
          itemName="smtpSecure"
          itemLabel="Verschlüsselung"
          :hideLabel="false"
          :route="`${patchRoute}`"
          inputElement="select"
          inputType=""
          :selectOptions="[
            { value: 'ssl', name: 'SSL' },
            { value: 'tls', name: 'TLS' },
          ]"
          itemValueKey="value"
          itemTextKey="name"
          :previewValue="data.smtpSecure"
          @emit="data.smtpSecure = $event.value"
        ></Patcher>

        <Patcher
          :itemValue="data.note"
          itemName="note"
          itemLabel="Notiz"
          :hideLabel="false"
          :route="`${patchRoute}`"
          inputElement="textarea"
          inputType=""
          selectOptions=""
          itemValueKey=""
          itemTextKey=""
          :previewValue="data.note"
          @emit="data.note = $event.value"
        ></Patcher>

        <v-card>
          <v-card-title>
            <h3>Testen</h3>
          </v-card-title>
          <v-card-text>
            <strong>Letzter Test:</strong> {{ data.checkDatetime }}<br />
            <strong>Ergebnis:</strong> {{ data.checkStatus }}<br />
            <strong>Antwort:</strong>
            <pre
              style="border: 1px solid #ddd; padding: 1em; border-radius: 3px"
              >{{ data.checkResponse }}</pre
            >
            <br />
            <v-btn
              @click="checkConnection(data.id)"
              :loading="checking"
              color="primary"
              class="mt-4"
              >Verbindung testen</v-btn
            >
          </v-card-text>
        </v-card>
      </template>

      <template v-else>
        Es wurden noch keine SMTP-Daten hinterlegt.<br />
        <v-btn
          @click="createItem"
          :loading="creating"
          color="primary"
          class="mt-4"
          >Hinzufügen</v-btn
        ></template
      >
    </v-card-text>
  </v-card>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import Patcher from "@/components/ui/Patcher.vue";
/* GET companies/{companyId}/smtp
GET PATCH DELETE companies/{companyId}/smtp/{smtpId}
GET companies/{companyId}/smtp/{smtpId}/checkConnection
POST companies/{companyId}/smtp */

export default {
  name: "SMTP",

  props: ["companyId"],

  components: {
    Patcher,
  },

  data() {
    return {
      data: [],
      preloader: false,
      checking: false,
      creating: false,
      patchRoute: `companies/${this.companyId}/smtp`,
    };
  },

  created: function () {
    this.getData(); // call it immediatly
  },

  methods: {
    async getData() {
      this.preloader = true;

      let response = await this.getRequest(
        "companies/" + this.companyId + "/smtp"
      );
      this.preloader = false;

      if (response) {
        this.data = response.data.data;
      }
    },

    async createItem() {
      this.creating = true;
      let response = await this.postRequest(
        "companies/" + this.companyId + "/smtp"
      );
      this.creating = false;

      if (response) {
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = "Eintrag wurde erstellt";
        this.$root.snackbarGlobal.errorMessage = "";
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
        this.getData(); // reload
      }
    },

    async deletePrompt(id) {
      console.log("deletePrompt", id);
      /* const userChoice = await this.$dialog.confirm({
        text: "Soll der Ansprechpartner wirklich gelöscht werden?",
        title: "Achtung!",
        waitForResult: true,
      });

      if (userChoice) {
        // okay
        this.preloader = true;
        let response = await this.deleteRequest(
          "companies/" + this.$route.params.id + "/contactPersons/" + id
        ); // await data from mixin
        this.preloader = false;

        if (response) {
          this.$root.snackbarGlobal.snackbar = true;
          this.$root.snackbarGlobal.snackbarText = "Eintrag wurde gelöscht";
          this.$root.snackbarGlobal.errorMessage = "";
          this.$root.snackbarGlobal.snackbarColor = "primary";
          this.$root.snackbarGlobal.snackbarTimeout = 5000;
          this.getData(); // reload
        }
      } else {
        // abort
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = "Vorgang abgebrochen!";
        this.$root.snackbarGlobal.errorMessage = "";
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      } */
    },

    async checkConnection() {
      this.checking = true;
      let response = await this.getRequest(
        `companies/${this.companyId}/smtp/checkConnection`
      );
      this.checking = false;

      if (response) {
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = "Verbindung wurde getestet";
        this.$root.snackbarGlobal.errorMessage = "";
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
        this.getData(); // reload
      }
    },
  },

  mixins: [apiRequest],
};
</script>
